@import '../../../../scss/theme-bootstrap';

.cart-item {
  @include pie-clearfix;
  display: block;
  border-bottom: $border;
  padding-bottom: $spacing;
  &:hover {
    color: $color-off-black;
    text-decoration: none;
  }
  // Layout:
  &__image {
    display: block;
    float: left;
    width: 85px;
  }
  &__info {
    clear: right;
    float: left;
    margin: 20px 0 0 $spacing;
    width: 180px; // 295 block width - 20 padding - 85 img - 10 left margin - 180
  }

  // Styles:
  &__qty {
    text-transform: uppercase;
  }
  &__shade {
    @include pie-clearfix;
    &__swatch {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 10px;
      float: left;
    }
  }
}
